<template>
  <v-container>
    <template>
      <v-card-title>
        Filialen auswählen
      </v-card-title>
      <v-card-text>
        <v-text-field
          class="shrink mb-3"
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          outlined
          hide-details
        ></v-text-field>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="shops"
          :search="search"
          :items-per-page="10"
          item-key="id"
          show-select
          class="elevation-1"
          @input="updateChart"
          sort-by="name"
          dense
        >
        </v-data-table>
      </v-card-text>
    </template>
    <div v-if="selected.length != 0">
      <div class="header pl-8">
        <h2>
          Gesamtentwicklung der Kunden
          <span class="subtitle-1"
            >{{ this.highestYearInFiveShownYears - 4 }}-{{
              this.highestYearInFiveShownYears
            }}</span
          >
        </h2>
        <v-col class="d-flex justify-end">
          <v-btn icon color="black" @click="increaseFiveYears">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>

          <v-btn icon color="black" @click="decreaseFiveYears">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </v-col>
      </div>

      <div id="yearChart">
        <apexchart
          ref="totalChart"
          type="line"
          height="350"
          :options="yearChartOptions"
          :series="total"
        ></apexchart>
      </div>
    </div>

    <div v-if="currentYear != 0">
      <div class="header">
        <h2>
          Monatsansicht <span class="subtitle-1">{{ currentYear }}</span>
        </h2>
        <v-col class="d-flex justify-end">
          <v-btn icon color="black" @click="increaseOneYear">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>

          <v-btn icon color="black" @click="decreaseOneYear">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </v-col>
      </div>

      <div id="monthChart">
        <apexchart
          ref="monthChart"
          type="line"
          height="350"
          :options="monthChartOptions"
          :series="year"
        ></apexchart>
      </div>

      <customerDatatable :items="newCustomersItems"></customerDatatable>
    </div>
  </v-container>
</template>

<script>
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js'
import customerDatatable from '@/components/statistics/customerDevelopment/customerDatatable.vue'
import { monthNames } from '@/services/statistics/chartService'

export default {
  components: {
    customerDatatable
  },
  data: function() {
    return {
      currentYear: 0,
      highestYearInFiveShownYears: new Date().getFullYear(),

      currentShownYearArrayInFiveYearsChart: [],
      fiveYearDataArray: [],

      total: [],
      year: [],
      yearChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showMonth(
                this.currentShownYearArrayInFiveYearsChart[
                  config.dataPointIndex
                ]
              )
              console.info(
                this.currentShownYearArrayInFiveYearsChart[
                  config.dataPointIndex
                ]
              )
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [
            new Date().getFullYear() - 4,
            new Date().getFullYear() - 3,
            new Date().getFullYear() - 2,
            new Date().getFullYear() - 1,
            new Date().getFullYear()
          ]
        }
      },
      monthChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showNewCustomers(config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        }
      },

      ///////////////////////////////////////////////////

      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        {
          text: 'Name',
          value: 'description',
          align: 'start',
          sortable: true
        },
        { text: 'Telefon', value: 'phone' },
        { text: 'Straße', value: 'street' },
        { text: 'PLZ', value: 'postcode' },
        { text: 'Stadt', value: 'city' }
      ],
      shops: [],

      /////////////////////////////////////////////////////
      //                newCustomerDatatable
      ////////////////////////////////////////////////////
      newCustomersItems: []
    }
  },
  methods: {
    updateChart() {
      let tempDataArray = []
      let newData = []
      this.currentYear = 0
      this.selected.forEach(row => {
        customerDevelopmentService
          .getStoreDeveolpmentOfFiveYears(
            this.highestYearInFiveShownYears - 4,
            row.id
          )
          .then(response => {
            newData = []
            for (let i = 0; i < response.data.length; i++) {
              newData[i] = response.data[i].customerNumber
            }
            tempDataArray.push({
              name: row.description,
              data: newData
            })
          })
      })
      this.total = tempDataArray
      this.$refs.totalChart.updateSeries(this.total)
    },
    showStores() {
      customerDevelopmentService.getDetaillisteOfAllStores().then(response => {
        this.shops = response.data
      })
    },

    increaseOneYear() {
      this.currentYear--
      this.showMonth(this.currentYear)
    },
    decreaseOneYear() {
      if (this.currentYear < new Date().getFullYear()) {
        this.currentYear++
        this.showMonth(this.currentYear)
      }
    },
    increaseFiveYears() {
      this.highestYearInFiveShownYears--

      let tempDataArray = []
      let newData = []
      this.selected.forEach(row => {
        customerDevelopmentService
          .getStoreDeveolpmentOfFiveYears(
            this.highestYearInFiveShownYears - 4,
            row.id
          )
          .then(response => {
            newData = []
            for (let i = 0; i < response.data.length; i++) {
              newData[i] = response.data[i].customerNumber
            }
            tempDataArray.push({
              name: row.description,
              data: newData
            })
          })
      })
      this.total = tempDataArray
      this.$refs.totalChart.updateSeries(this.total)

      this.$refs.totalChart.updateOptions({
        xaxis: {
          categories: [
            this.highestYearInFiveShownYears - 4,
            this.highestYearInFiveShownYears - 3,
            this.highestYearInFiveShownYears - 2,
            this.highestYearInFiveShownYears - 1,
            this.highestYearInFiveShownYears
          ]
        }
      }),
        (this.currentShownYearArrayInFiveYearsChart = [
          this.highestYearInFiveShownYears - 4,
          this.highestYearInFiveShownYears - 3,
          this.highestYearInFiveShownYears - 2,
          this.highestYearInFiveShownYears - 1,
          this.highestYearInFiveShownYears
        ])
    },
    decreaseFiveYears() {
      if (this.highestYearInFiveShownYears < new Date().getFullYear()) {
        this.highestYearInFiveShownYears++

        let tempDataArray = []
        let newData = []
        this.selected.forEach(row => {
          customerDevelopmentService
            .getStoreDeveolpmentOfFiveYears(
              this.highestYearInFiveShownYears - 4,
              row.id
            )
            .then(response => {
              newData = []
              for (let i = 0; i < response.data.length; i++) {
                newData[i] = response.data[i].customerNumber
              }
              tempDataArray.push({
                name: row.description,
                data: newData
              })
            })
        })
        this.total = tempDataArray
        this.$refs.totalChart.updateSeries(this.total)

        this.$refs.totalChart.updateOptions({
          xaxis: {
            categories: [
              this.highestYearInFiveShownYears - 4,
              this.highestYearInFiveShownYears - 3,
              this.highestYearInFiveShownYears - 2,
              this.highestYearInFiveShownYears - 1,
              this.highestYearInFiveShownYears
            ]
          }
        }),
          (this.currentShownYearArrayInFiveYearsChart = [
            this.highestYearInFiveShownYears - 4,
            this.highestYearInFiveShownYears - 3,
            this.highestYearInFiveShownYears - 2,
            this.highestYearInFiveShownYears - 1,
            this.highestYearInFiveShownYears
          ])
      }
    },

    showMonth(year) {
      this.currentYear = year
      let tempDataArray = []
      let newData = []
      this.selected.forEach(row => {
        customerDevelopmentService
          .getStoreDeveolpmentOfOneYear(year, row.id)
          .then(response => {
            newData = []
            for (let i = 0; i < response.data.length; i++) {
              newData[i] = response.data[i].customerNumber
            }
            tempDataArray.push({
              name: row.description,
              data: newData
            })
          })
      })
      this.year = tempDataArray
      this.$refs.monthChart.updateSeries(this.year)
    },
    initializeTimeLineLables() {
      this.currentShownYearArrayInFiveYearsChart = [
        new Date().getFullYear() - 4,
        new Date().getFullYear() - 3,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 1,
        new Date().getFullYear()
      ]
    },

    showNewCustomers(index) {
      let month = index + 1

      let locationIds = this.selected.map(location => location.id)
      console.log(locationIds)

      this.newCustomersItems = []
      customerDevelopmentService
        .getListOfNewCustomersForLocations(this.currentYear, month, locationIds)
        .then(response => {
          this.newCustomersItems = response.data
        })
    }
  },
  beforeMount() {
    this.showStores()
    this.initializeTimeLineLables()
  }
}
</script>
