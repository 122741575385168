<template>
  <div>
    <v-text-field
      class="my-5"
      v-model="search"
      append-icon="mdi-magnify"
      label="Suche"
      single-line
      hide-details
      outlined
    ></v-text-field>
    <v-data-table
      :headers="header"
      :items="items"
      :items-per-page="10"
      :search="search"
      item-key="kdnr"
      class="elevation-1"
    >
      <template #item.actions="{ item }">
        <OpenLinkEyeComponent
          :destination="{
            name: 'crmAddress',
            params: { lfdnr: item.kdnr }
          }"
          :newTab="true"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import OpenLinkEyeComponent from '@/components/core/OpenLinkEyeComponent.vue'

export default {
  components: {
    OpenLinkEyeComponent
  },
  props: {
    items: { type: Array, required: true }
  },
  data: () => ({
    search: '',
    header: [
      {
        text: 'Name',
        value: 'name',
        align: 'start',
        sortable: true
      },
      {
        text: 'Telefonnummer',
        value: 'tel',
        align: 'start',
        sortable: true
      },
      {
        text: 'Kundennummer',
        value: 'kdnr',
        align: 'start',
        sortable: true
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
        sortable: false
      }
    ]
  })
}
</script>
