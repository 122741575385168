<template>
  <v-container>
    <div class="mx-5">
      <h2 class="mb-3">Kundengruppe Auswählen</h2>
      <v-select
        class="mb-5 mt-3"
        :items="customerGroupsArray"
        item-text="name"
        item-value="id"
        label="Kundengruppe"
        v-model="selectedGroup"
        @input="showFiveYears"
      ></v-select>
      <div v-if="selectedGroup != ''">
        <fiveYearGraph
          :highestYearInFiveShownYears="highestYearInFiveShownYears"
          :fiveYearDataArray="fiveYearDataArray"
          :headLineText="headLineText"
          :entireCustomerInFiveYears="entireCustomerInFiveYears"
          :activeCustomer="activeCustomer"
          :inactiveCustomer="inactiveCustomer"
          @increaseOneYearInFiveYearChart="increaseOneYearInFiveYearChart"
          @decreaseOneYearInFiveYearChart="decreaseOneYearInFiveYearChart"
          @showMonth="showMonth"
        />
      </div>
      <div
        v-else
        class="text-center mx-auto my-8 py-3 text--disabled"
        style="width:30%"
      >
        Wählen Sie eine Kundengruppe
      </div>

      <div v-if="currentYear != 0">
        <monthGraph
          :currentYear="currentYear"
          :monthlyData="newCustomerPerMonth"
          :entireCustomerData="entireCustomerData"
          :activeCustomer="activeCustomerOfMonth"
          :inactiveCustomer="inactiveCustomerOfMonth"
          @increaseOneYear="increaseOneYear"
          @decreaseOneYear="decreaseOneYear"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js'
import monthGraph from '@/components/statistics/customerDevelopment/monthGraph.vue'
import fiveYearGraph from '@/components/statistics/customerDevelopment/fiveYearGraph.vue'
//import customerDatatable from '@/components/statistics/customerDevelopment/customerDatatable.vue'

export default {
  components: {
    monthGraph,
    fiveYearGraph
    //customerDatatable
  },
  data: function() {
    return {
      currentYear: 0,
      highestYearInFiveShownYears: new Date().getFullYear(),
      responseDataArray: [],
      fiveYearDataArray: [],
      customerGroupsArray: [],
      selectedGroup: '',
      entireCustomerData: [],
      activeCustomerOfMonth: [],
      inactiveCustomerOfMonth: [],
      newCustomerPerMonth: [],
      entireCustomerInMonth: [],
      activeCustomer: [],
      inactiveCustomer: [],
      headLineText: 'Kundenentwicklung nach Kundengruppen',
      newCustomerDatatableItems: []
    }
  },
  methods: {
    showFiveYears() {
      this.fiveYearDataArray = []
      this.entireCustomerInFiveYears = []
      this.activeCustomer = []
      this.inactiveCustomer = []

      customerDevelopmentService
        .getCustomerGroupsDevelopmentOfFiveYears(
          this.highestYearInFiveShownYears - 4,
          this.selectedGroup
        )
        .then(response => {
          response.data.forEach(customerGroup => {
            this.fiveYearDataArray.push(customerGroup.customerNumber)
            this.entireCustomerInFiveYears.push(customerGroup.totalCustomer)
            this.activeCustomer.push(customerGroup.activeCustomer)
            this.inactiveCustomer.push(customerGroup.inactiveCustomer)
          })
        })
    },
    increaseOneYear() {
      this.currentYear--
      this.showMonth(this.currentYear)
    },
    decreaseOneYear() {
      if (this.currentYear < new Date().getFullYear()) {
        this.currentYear++
        this.showMonth(this.currentYear)
      }
    },
    increaseOneYearInFiveYearChart() {
      this.highestYearInFiveShownYears--
      this.showFiveYears()
    },
    decreaseOneYearInFiveYearChart() {
      if (this.highestYearInFiveShownYears < new Date().getFullYear()) {
        this.highestYearInFiveShownYears++
        this.showFiveYears()
      }
    },

    showMonth(year) {
      this.currentYear = year
      this.newCustomerPerMonth = []
      this.entireCustomerData = []
      this.activeCustomerOfMonth = []
      this.inactiveCustomerOfMonth = []
      customerDevelopmentService
        .getCustomerGroupsDevelopmentOfOneYear(
          this.currentYear,
          this.selectedGroup
        )
        .then(response => {
          response.data.forEach(month => {
            this.newCustomerPerMonth.push(month.customerNumber)
            this.entireCustomerData.push(month.totalCustomers)
            this.activeCustomerOfMonth.push(month.activeCustomer)
            this.inactiveCustomerOfMonth.push(month.inactiveCustomer)
          })
        })
    },

    showCustomerGroups() {
      customerDevelopmentService.getListOfAllCustomerGroups().then(response => {
        response.data.forEach(customerGroup => {
          this.customerGroupsArray.push(customerGroup)
        })
      })
    }
  },
  beforeMount() {
    this.showCustomerGroups()
  }
}
</script>
