<template>
  <div>
    <RevenueChart
      ref="monthChart"
      chartType="month"
      :chartSeries="chartSeries"
      :chartYaxisData="getMonthLabels"
      :totalSeries="totalSeries"
      :legendItems="[]"
      :titleText="`Monatsvergleich - ${getTitle}`"
      xaxisText="Monat"
      :yaxisText="getTitle"
      :stackedChart="false"
      :showBothRevenueTypes="showBothRevenueTypes"
      @updateCharts="$emit('updateCharts')"
    ></RevenueChart>
  </div>
</template>

<script>
// Services
import RevenueService from '@/services/statistics/revenueService.js'

// Components
import RevenueChart from '@/components/statistics/Revenue/RevenueChart.vue'

// Vue Store
import { mapGetters } from 'vuex'

export default {
  components: {
    RevenueChart
  },
  props: {
    showBothRevenueTypes: Boolean
  },
  data: () => ({
    totalSeries: [],
    chartSeries: []
  }),
  computed: {
    ...mapGetters([
      'getYearStart',
      'getYearEnd',
      'getShowBothRevenueTypes',
      'getSelectedRevenueTypeShort',
      'getSelectedFilters'
    ]),

    getMonthLabels() {
      const monthLabels = []
      const date = new Date()

      for (let month = 0; month < 12; month++) {
        date.setMonth(month)
        const monthLabel = date.toLocaleString('default', { month: 'short' })
        monthLabels.push(monthLabel)
      }

      return monthLabels
    },

    getTitle() {
      return RevenueService.getRevenueName(this.getRevenueTypeShort)
    },
    getRevenueTypeShort() {
      if (this.getShowBothRevenueTypes && this.showBothRevenueTypes) {
        return 'db'
      } else if (this.getShowBothRevenueTypes && !this.showBothRevenueTypes) {
        return 'net'
      }
      return this.getSelectedRevenueTypeShort
    }
  },
  methods: {
    updateShownMonth() {
      let chartSeries = []
      let totalSeries = []

      if (
        this.getYearEnd < this.getYearStart ||
        this.getYearStart > this.getYearEnd
      ) {
        this.chartSeries = chartSeries
        this.totalSeries = totalSeries
        return
      }

      let yearArray = []
      let monthArray = []
      for (let year = this.getYearStart; year <= this.getYearEnd; year++) {
        yearArray.push(year)
      }
      for (let month = 1; month <= 12; month++) {
        monthArray.push(month)
      }

      RevenueService.getMonthlyRevenueForYear(
        yearArray,
        this.getSelectedFilters
      ).then(res => {
        if (res.status === 200) {
          if (!RevenueService.isRevenueResponseValid(res)) {
            this.chartSeries = chartSeries
            this.totalSeries = totalSeries
            return
          }

          yearArray.forEach(year => {
            monthArray.forEach(month => {
              totalSeries.push({
                year: year,
                month: month,
                net: res.data[year].monthRevenue[month].net,
                db: res.data[year].monthRevenue[month].db
              })
            })
            chartSeries.push({
              data: [],
              name: year,
              type: 'bar'
            })
          })

          chartSeries.forEach(seriesObject => {
            monthArray.forEach(month => {
              seriesObject.data.push(
                res.data[seriesObject.name].monthRevenue[month][
                  this.getRevenueTypeShort
                ]
              )
            })
          })

          this.chartSeries = chartSeries
          this.totalSeries = totalSeries
        } else {
          this.$store.dispatch(
            'setFailMessage',
            'Es trat ein Fehler beim Laden der Statistik auf: Monatsvergleich'
          )
          this.$store.dispatch('setErrorDialog', true)
          console.error(res.stack)
        }
      })
    }
  },
  mounted() {
    if (this.showBothRevenueTypes) {
      this.updateShownMonth()
    }
  }
}
</script>
<style scoped>
.v-select__selections {
  font-weight: bold;
  padding-right: 50px;
}
.v-select.fit {
  width: 100px;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
</style>
