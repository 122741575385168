<template>
  <div>
    <div class="header">
      <h2>
        Monatsansicht <span class="subtitle-1">{{ currentYear }}</span>
      </h2>

      <v-col class="d-flex justify-end">
        <v-btn icon color="black" @click="$emit('increaseOneYear')">
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>

        <v-btn icon color="black" @click="$emit('decreaseOneYear')">
          <v-icon>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </v-col>
    </div>

    <div id="chart">
      <apexchart
        ref="monthChart"
        type="line"
        height="350"
        :options="monthChartOptions"
        :series="year"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { monthNames } from '@/services/statistics/chartService'
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js'

export default {
  props: {
    currentYear: { type: Number, required: true },
    monthlyData: { type: Array, required: true },
    activeCustomer: { type: Array, required: true },
    inactiveCustomer: { type: Array, required: true },
    entireCustomerData: { type: Array, required: true },
    fiveYearDataArray: { type: Array, required: true }
  },
  computed: {
    /*addressTypeOptions() {
      return this.addressTypesItems.map(data => ({
        id: data.id,
        name: data.name
      }))
    }*/
  },

  data: function() {
    return {
      year: [
        {
          name: 'Neu',
          data: this.monthlyData
        },
        {
          name: 'Gesamt',
          data: this.entireCustomerData
        },
        {
          name: 'Aktiv',
          data: this.activeCustomer
        },
        {
          name: 'Inaktiv',
          data: this.inactiveCustomer
        }
      ],
      monthChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        }
      }
    }
  },
  methods: {
    updateChart() {
      this.$refs.monthChart.updateSeries([
        {
          name: 'Neu',
          data: this.monthlyData
        },
        {
          name: 'Gesamt',
          data: this.entireCustomerData
        },
        {
          name: 'Aktiv',
          data: this.activeCustomer
        },
        {
          name: 'Inaktiv',
          data: this.inactiveCustomer
        }
      ])
    },
    selectedTotalCustomerDevelopmentOfYear() {
      /*year,
      locations,
      addressType,
      customerGroup*/

      customerDevelopmentService
        .getTotalCustomerDevelopmentOfYear(
          this.currentYear,
          this.selectBranche,
          this.selectedAddressType,
          this.selectedGroup
        )
        .then(response => {
          const yearData = response.data.find(
            year => year.year === this.yearStartSelection
          )

          this.activeCustomer = [yearData.activeCustomer]
          this.inactiveCustomer = [yearData.inactiveCustomer]
          this.entireCustomerData = [yearData.totalCustomer]
          this.monthlyData = [yearData.newCustomer]
          /*const year = response.data.year
          const locations = response.data.locations
          const addressType = response.data.addressType
          const customerGroup = response.data.customerGroup*/
          this.updateChart()
          console.log('test')

          //const data = { year: year, locations: locations }
          console.log(
            '--------------------------------------------------------------------'
          )
          console.log(
            '--------------------------------------------------------------------'
          )
          //console.log(year, locations, addressType, customerGroup)
          //this.testItems = response.data
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  watch: {
    monthlyData() {
      this.updateChart()
    }
  },
  created() {
    this.selectedTotalCustomerDevelopmentOfYear()
  }
}
</script>

<style></style>
