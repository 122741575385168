<template>
  <v-container>
    <apexchart
      ref="revenueChart"
      type="bar"
      height="500"
      :options="chartOptions"
      :series="chartSeries"
    >
    </apexchart>
  </v-container>
</template>

<script>
// Services
import FormatService from '@/services/formatters/currencyFormatService.js'
import RevenueService from '@/services/statistics/revenueService.js'

// Vue Store
import { mapGetters } from 'vuex'

export default {
  props: {
    chartType: String,
    chartSeries: Array,
    chartYaxisData: Array,
    totalSeries: Array,
    legendItems: Array,
    titleText: String,
    xaxisText: String,
    yaxisText: String,
    stackedChart: Boolean,
    showBothRevenueTypes: Boolean
  },
  methods: {
    getMonthLabel(month) {
      const date = new Date()
      date.setMonth(month - 1)

      return date.toLocaleString('default', { month: 'long' })
    },

    getRevenuesByTypeAndMonth(dataPointIndex) {
      const filteredData = this.totalSeries.filter(entry => {
        return entry.month === this.totalSeries[dataPointIndex].month
      })

      const revenues = filteredData.map(entry => {
        return {
          year: entry.year,
          revenue: entry[this.revenueTypeShort]
        }
      })

      const htmlString = revenues
        .map((revenue, index) => {
          return `
      <${index == revenues.length - 1 ? 'span' : 'p'}>
        <b>${revenue.year}</b>: ${FormatService.formatEuro(revenue.revenue)}
      </${index == revenues.length - 1 ? 'span' : 'p'}>
    `
        })
        .join('')

      return htmlString
    },

    getRevenuesByTypeAndCategory(dataPointIndex) {
      let title = this.totalSeries[dataPointIndex].title

      return `
                <div class="grey lighten-2 py-1">
                        <span class="ps-2">${title}</span>
                    </div>
                    <div class="py-2 px-2">
                        <span>
                            <b>${this.title}</b>: ${FormatService.formatEuro(
        this.totalSeries[dataPointIndex][this.revenueTypeShort]
      )}
                        </span>
                    </div>
                `
    },

    updateSeries(seriesData) {
      this.$refs.revenueChart.updateSeries(seriesData)
    }
  },
  computed: {
    ...mapGetters([
      'getShowBothRevenueTypes',
      'getSelectedRevenueTypeShort',
      'getSelectedCategory'
    ]),

    chartOptions() {
      return {
        chart: {
          type: 'bar',
          stacked: this.stackedChart,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        title: {
          align: 'left',
          style: {
            fontSize: this.getShowBothRevenueTypes ? '16px' : '20px'
          },
          text: this.titleText
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          customLegendItems: this.legendItems,
          markers: {
            radius: 12
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          title: {
            text: this.xaxisText
          },
          categories: this.chartYaxisData
        },
        yaxis: [
          {
            title: {
              text: this.yaxisText
            },
            decimalsInFloat: 0,
            labels: {
              formatter: value => FormatService.formatEuro(value)
            },
            forceNiceScale: true
          }
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(value) {
              return FormatService.formatEuro(value)
            }
          },
          custom: ({ dataPointIndex }) => {
            if (this.chartType == 'year') {
              return `
                <div class="grey lighten-2 py-1">
                        <span class="ps-2">${
                          this.totalSeries[dataPointIndex].title
                        }</span>
                    </div>
                    <div class="py-2 px-2">
                        <span>
                            <b>${RevenueService.getRevenueName(
                              this.revenueTypeShort
                            )}</b>: ${FormatService.formatEuro(
                this.totalSeries[dataPointIndex][this.revenueTypeShort]
              )}
                        </span>
                    </div>
                `
            } else if (this.chartType == 'month') {
              return `
                <div class="grey lighten-2 py-1">
                        <span class="ps-2">${this.title} - ${this.getMonthLabel(
                this.totalSeries[dataPointIndex].month
              )}</span>
                    </div>
                    <div class="py-2 px-2">
                      ${this.getRevenuesByTypeAndMonth(dataPointIndex)}
                    </div>
                `
            } else if (this.chartType == 'category') {
              return this.getRevenuesByTypeAndCategory(dataPointIndex)
            }
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' // none, lighten, darken
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth:
              50 +
              60 / (1 + 30 * Math.exp(-(this.chartSeries.length / 2) / 3)) +
              '%'
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: [
          '#0DC0F6',
          '#55D7FF',
          '#8AE4FF',
          '#C0F0FF',
          '#2A95B6',
          '#0E7392',
          '#02546D',
          '#053E4F',
          '#053E4F'
        ],

        noData: {
          text:
            'Mit den angewandten Filtern konnten keine Daten gefunden werden'
        }
      }
    },

    title() {
      return RevenueService.getRevenueName(this.revenueTypeShort)
    },

    revenueTypeShort() {
      return this.getShowBothRevenueTypes
        ? this.showBothRevenueTypes
          ? 'db'
          : 'net'
        : this.getSelectedRevenueTypeShort
    }
  }
}
</script>

<style></style>
